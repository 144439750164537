//
//
//
//
//
//
//
//
//
//
//
//

import Navigation from '~/components/layout/Navigation'
import NavigationSideBar from '~/components/layout/SideNav'
import Cart from '~/components/layout/Cart'
import CartIco from '~/components/layout/CartIco'
import ConfirmOrder from '~/components/layout/ConfirmOrder'
import Footer from '~/components/layout/Footer'
import * as Panelbear from "@panelbear/panelbear-js";


const PANEL_BEAR = process.env.PANEL_BEAR
console.log('PANEL_BEAR = = '+PANEL_BEAR);

export default {
	components: {
		Navigation, NavigationSideBar, Cart, ConfirmOrder, CartIco, Footer
	},

	watch: {
        '$route': function() {
					Panelbear.trackPageview();
        	if(this.$store.getters['nav/toggleSidebar']){
                this.$store.dispatch('nav/toggleSidebar')
                document.querySelector('body').style.removeProperty('overflow')
            }
        }
    },
	data() {
		return {
			panelBearID: this.$config.PANEL_BEAR
		};
	},
	head () {
		const metaArray = [];
		var metatitle = '';
		if(this.$store.state.post && this.$store.state.post.yoast_meta){
			this.$store.state.post.yoast_meta.map(ele => {
				if(ele.name!='robots'){
					var my_content = ele.content
					if(ele.property!='og:image')
						my_content = ele.content.replace('-admin', '')
					metaArray.push({
						hid: ele.name ? ele.name : ele.property,
						name: ele.name ? ele.name : ele.property,
						content: my_content,
					});
				}
			});
			metatitle = this.$store.state.post.yoast_meta[4].content
		}
		return {
			title: metatitle ,
			meta: metaArray,
		}
	},

	mounted() {
		console.log('trackPageview = '+this.panelBearID)
		Panelbear.load(this.panelBearID);
		panelbear("config", { debug: true });
		Panelbear.trackPageview();
	},

}
