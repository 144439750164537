import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=b3dc7780&scoped=true&"
import script from "./Logo.vue?vue&type=script&lang=js&"
export * from "./Logo.vue?vue&type=script&lang=js&"
import style0 from "./Logo.vue?vue&type=style&index=0&id=b3dc7780&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3dc7780",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoPath: require('/opt/atlassian/pipelines/agent/build/components/LogoPath.vue').default})
